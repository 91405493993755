import React, { useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { getImage } from '../../../server/server';
import { ReactComponent as DeleteIcon } from '../../../assets/svg/DeleteIcon.svg';
import SingleImageModal from '../../media/SingleImageModal';
import { Button } from '@mui/material';
import SingleSpecificationModal from './SingleSpecificationModal';

interface SpecificationProps {
	specificationList: SpecificationItem[];
	setSpecificationList: any;
	width?: 'col-span-1' | 'col-span-2';
}

interface SpecificationItem {
	id: string;
	text: string;
	image: string;
	order: number;
}

function Specification({ specificationList, setSpecificationList, width }: SpecificationProps) {
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [specificationModalIsOpen, setSpecificationModalIsOpen] = useState(false);
	const [selectedPicture, setSelectedPicture] = useState<any>(null);
	const [selectedSpecification, setSelectedSpecification] = useState<any>(null);

	const removeSpecificationItem = (index: number) => {
		let newList = [...specificationList];
		newList.splice(index, 1);
		setSpecificationList(newList);
	};

	const handleDrop = (droppedItem: any) => {
		// Ignore drop outside droppable container
		if (!droppedItem.destination) return;
		var updatedList = [...specificationList];
		// Remove dragged item
		const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
		// Add dropped item
		updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
		let newReorderList = updatedList.map((item, index) => {
			return { ...item, order: index + 1 };
		});
		// Update State
		setSpecificationList(newReorderList);
	};

	const openSingleImageModal = (item: any) => {
		setSelectedPicture(item);
		setModalIsOpen(true);
	};
	const closeModal = () => {
		setSelectedPicture(null);
		setModalIsOpen(false);
	};

	const openSpecificationModal = (e: any) => {
		e.preventDefault();
		setSpecificationModalIsOpen(true);
		setSelectedSpecification({});
	};
	const closeSpecificationModal = () => {
		setSpecificationModalIsOpen(false);
	};

	const saveSpecification = (specification: any) => {
		if (!!specification.order) {
			let newList = [...specificationList];
			newList[specification.order] = specification;
			setSpecificationList(newList);
		} else {
			specification.order = specificationList.length;
			setSpecificationList([...specificationList, specification]);
		}
		setSpecificationModalIsOpen(false);
	};

	return (
		<div className={`border border-mediumGrey rounded-md py-[20px] px-3 ${width} flex flex-col `}>
			<div className="flex flex-row items-center justify-between ">
				<p>Specification</p>
				<Button variant="contained" color="primary" size="medium" onClick={openSpecificationModal}>
					Add specification
				</Button>
			</div>
			<div className="flex flex-row min-h-[100px]">
				<DragDropContext onDragEnd={handleDrop}>
					<Droppable droppableId="list-container" direction="horizontal">
						{(provided) => (
							<div {...provided.droppableProps} ref={provided.innerRef} className="flex flex-row gap-[10px]">
								{specificationList?.map((item: any, index: number) => {
									return (
										<Draggable key={index.toString()} draggableId={index.toString()} index={index}>
											{(provided) => (
												<div className=" bg-white transition-colors hover:bg-babyBlue relative  border border-mediumGrey mt-1 p-[6px]" ref={provided.innerRef} {...provided.draggableProps}>
													<div className="flex flex-col items-center justify-between" {...provided.dragHandleProps}>
														<img
															src={getImage(item.image.id ?? item.image)}
															key={item.image.id ?? item.image}
															alt="product"
															style={{ minHeight: 80, maxHeight: 80, aspectRatio: '1/1', objectFit: 'cover', objectPosition: 'top', cursor: 'pointer' }}
															onClick={() => openSingleImageModal(item)}
														/>
														<p>{item.text}</p>
														<div className="cursor-pointer mr-5 absolute bg-white p-[10px] rounded-full  -right-5 top-0 border border-gray" onClick={() => removeSpecificationItem(index)}>
															<DeleteIcon />
														</div>
													</div>
												</div>
											)}
										</Draggable>
									);
								})}
								{provided.placeholder}
							</div>
						)}
					</Droppable>
				</DragDropContext>
			</div>
			<SingleImageModal modalIsOpen={modalIsOpen} closeModal={closeModal} selectedPicture={selectedPicture} deleteFlag={false} />
			<SingleSpecificationModal modalIsOpen={specificationModalIsOpen} closeModal={closeSpecificationModal} specification={selectedSpecification} save={saveSpecification} />
		</div>
	);
}

export default Specification;
