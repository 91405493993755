import React, { useEffect, useRef, useState } from 'react';
import ReactModal from 'react-modal';
import { FaCheck, FaTimes } from 'react-icons/fa';
import { Button, TextField } from '@mui/material';
import { getGallery, getImage } from '../../server/server';
import { GridRowSelectionModel } from '@mui/x-data-grid';
import SingleImageModal from './SingleImageModal';
import Loading from '../../common/Loading';

function AddFileFromLibraryModal({ modalIsOpen, closeModal, returnSelectedFile = () => {}, galleryType = 'media' }: any) {
	const [mediaList, setMediaList] = useState<any>([]);
	const [rowCountState, setRowCountState] = useState(5);
	const [isLoading, setIsLoading] = useState(false);
	const [isLoadingMore, setIsLoadingMore] = useState(false);
	const [searchQuery, setSearchQuery] = useState('');
	const [paginationModel, setPaginationModel] = useState({
		page: 0,
		pageSize: 18,
	});
	const [rowSelectionModel, setRowSelectionModel] = useState<any>([]);
	const [modalImageIsOpen, setModalImageIsOpen] = useState(false);
	const [selectedImage, setSelectedImage] = useState(null);
	const timeout = useRef<any>(null);

	useEffect(() => {
		if (modalIsOpen) {
			ReactModal.setAppElement('#root');
		}
	}, [modalIsOpen]);
	useEffect(() => {
		if (modalIsOpen) {
			loadMedia(paginationModel.page, paginationModel.pageSize, searchQuery);
		}
	}, [paginationModel.page, modalIsOpen]);

	const loadMedia = async (page: any, pageSize: any, searchQuery?: any, filter?: any) => {
		if (paginationModel.page === 0) {
			setIsLoading(true);
		} else {
			setIsLoadingMore(true);
		}
		const resp = await getGallery(pageSize, page * pageSize, searchQuery, galleryType);
		if (resp.status === 200) {
			if (paginationModel.page === 0) {
				setMediaList(resp.data.items);
			} else {
				setMediaList([...mediaList, ...resp.data.items]);
			}
			setRowCountState(resp.data.total);
			setIsLoading(false);
			setIsLoadingMore(false);
		} else {
			setIsLoading(false);
			setIsLoadingMore(false);
		}
	};

	const onSubmit = async () => {
		if (rowSelectionModel.length === 0) {
			alert('No file selected');
			return;
		}
		returnSelectedFile(rowSelectionModel[0]);
		closeModal();
	};

	const closeImageModal = () => {
		setSelectedImage(null);
		setModalImageIsOpen(false);
	};

	const loadMore = () => {
		if (paginationModel.page * paginationModel.pageSize < rowCountState) {
			setPaginationModel({
				page: paginationModel.page + 1,
				pageSize: paginationModel.pageSize,
			});
		}
	};

	const handleSearchQueryChange = (e: any) => {
		setSearchQuery(e.target.value);
		if (timeout.current != null) clearTimeout(timeout.current);
		timeout.current = setTimeout(() => {
			if (paginationModel.page === 0) {
				loadMedia(paginationModel.page, paginationModel.pageSize, e.target.value);
			} else {
				setPaginationModel({
					page: 0,
					pageSize: 18,
				});
			}
		}, 700);
	};

	const handleSelectImage = (newItem: any) => {
		setRowSelectionModel([newItem.id]);
	};

	const resetModalValues = () => {
		setRowSelectionModel([]);
		setPaginationModel({
			page: 0,
			pageSize: 18,
		});
		setSearchQuery('');
	};

	return (
		<ReactModal
			ariaHideApp={false}
			//closeTimeoutMS={600}
			isOpen={modalIsOpen}
			onAfterOpen={() => {}}
			onAfterClose={() => resetModalValues()}
			onRequestClose={closeModal}
			className="no-scroll"
			style={{
				overlay: {
					zIndex: 5,
					backdropFilter: 'brightness(30%)',
				},
				content: {
					padding: 0,
					opacity: 1,
					width: 950,
					margin: 'auto',
					marginTop: 20,
					backgroundColor: 'transparent',
					height: '100%',
					maxHeight: '95%',
					border: 'none',
					boxShadow: 'none',
					overflow: 'hidden',
				},
			}}
		>
			<div className="h-full  w-full  ">
				<div className=" h-full flex  flex-col gap-5 rounded-[10px] bg-white px-[40px] py-[30px] ">
					<p className=" text-center text-xl ">Select image from library</p>
					<div className="flex flex-row items-center justify-between border border-[#aeaeae] p-2 shadow-md">
						<TextField
							label="Search"
							value={searchQuery}
							onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
								handleSearchQueryChange(event);
							}}
						/>
					</div>
					{!isLoading && (
						<div className="max-h-[calc(100%-200px)] overflow-y-auto gray-small-scroll">
							<div className="grid grid-cols-6 gap-3 mt-1 pl-1 pr-2">
								{mediaList.map((item: any, index: number) => (
									<div className={`flex flex-col border border-[#aeaeae] cursor-pointer relative ${rowSelectionModel.includes(item.id) ? 'shadow-md shadow-darkBlue' : ''}`} title={item.name} onClick={() => handleSelectImage(item)} key={index}>
										<img className=" aspect-square object-cover object-top" src={getImage(item.id)} alt="" height={'100%'} />
										<p className="w-full line-clamp-2 text-center">{item.name}</p>
										{rowSelectionModel.includes(item.id) && (
											<div className="absolute -top-1 -right-1 border border-darkBlue p-[1px] bg-white">
												<div className="bg-darkBlue p-[2px]">
													<FaCheck color="white" />
												</div>
											</div>
										)}
									</div>
								))}
							</div>
							{!isLoadingMore && (
								<div className="flex flex-col items-center justify-center mt-5 gap-3">
									{mediaList.length === 0 && <p className="text-[#4e4e4e] text-base">No media files added</p>}
									{mediaList.length !== 0 && (
										<p className="text-[#4e4e4e] text-base">
											Showing {mediaList.length} of {rowCountState} media items
										</p>
									)}
									{rowCountState > (paginationModel.page + 1) * paginationModel.pageSize && (
										<Button variant="outlined" onClick={() => loadMore()} style={{ backgroundColor: '#0F2765', color: 'white' }}>
											Load more
										</Button>
									)}
								</div>
							)}
							{isLoadingMore && (
								<div className="py-5 flex items-center justify-center">
									<Loading height={50} />
								</div>
							)}
						</div>
					)}
					{isLoading && (
						<div className="h-[calc(100%-200px)] max-h-[calc(100%-200px)] flex items-center justify-center">
							<Loading />
						</div>
					)}
					<div className="flex w-full flex-row justify-end gap-8 mt-3  ">
						<Button variant="contained" onClick={() => closeModal()} style={{ backgroundColor: '#2ec4b6', color: 'white' }}>
							Cancel
						</Button>
						<Button variant="contained" onClick={() => onSubmit()} style={{ backgroundColor: '#2ec4b6', color: 'white' }}>
							Submit
						</Button>
					</div>
				</div>
			</div>
			<SingleImageModal modalIsOpen={modalImageIsOpen} closeModal={closeImageModal} selectedPicture={selectedImage} deleteFlag={false} />
		</ReactModal>
	);
}

export default AddFileFromLibraryModal;
