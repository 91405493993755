import React, { useRef } from 'react';
import { TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { Controller } from 'react-hook-form';

function PriceForm({ product, register, errors, setValue, control, getValues, trigger }: { product: any; register: any; errors: any; setValue: any; control: any; getValues: any; trigger: any }) {
	const datePickerRef = useRef<any>();

	const validateSaleTo = (endDate: any) => {
		const startDate = getValues('saleFrom');
		if (!startDate && endDate) {
			return 'Start date must exists if you set End date';
		}
		if (startDate && endDate < startDate) {
			return 'End date must be equal or greater than start date';
		}
		return true;
	};
	const validateDate = (value: any) => {
		const startDate = getValues('saleFrom');
		let dateValue = dayjs(new Date()).toDate();
		if (startDate && startDate < dateValue) {
			return 'Start date must greater than today';
		}
		return true;
	};

	const handleModalClose = () => {
		// Ručno pokrenite validaciju kada se modal zatvori
		//clearErrors('date'); // Ovo će očistiti postojeće greške ako postoje
		validateDate(datePickerRef.current.value); // Pokreni validaciju
	};

	return (
		<div className="flex  w-full flex-col  border border-mediumGrey rounded-md py-[20px] px-3 gap-5">
			<div className="grid grid-cols-8 gap-2">
				<div className="flex flex-row items-center col-span-2">
					<p className="text-sm">
						Regular price <span>({product?.zone?.currency?.name})</span>
					</p>
				</div>
				<div className="col-span-5">
					<TextField
						type="text"
						className=" background-transparent "
						helperText={errors.price?.message}
						error={!!errors.price}
						fullWidth
						label="Regular price"
						variant="filled"
						{...register('price', {
							required: 'Field is required',
							pattern: {
								value: /^[+-]?\d+(\.\d+)?$/, // Dozvoljava decimalni zapis broja
								message: 'Must be a numeric value',
							},
							validate: {
								greaterThanZero: (value: any) => parseFloat(value) > 0 || 'Must be greater than zero',
							},
						})}
					/>
				</div>
				<div className="flex flex-row items-center">
					<p></p>
				</div>
			</div>
			<div className="grid grid-cols-8 gap-2">
				<div className="flex flex-row items-center col-span-2">
					<p className="text-sm">
						Sale price <span>{product.zone?.symbol}</span>
					</p>
				</div>
				<div className="col-span-5">
					<TextField
						type="text"
						className=" background-transparent "
						helperText={errors.salePrice?.message}
						error={!!errors.salePrice}
						fullWidth
						label="Sale price"
						variant="filled"
						{...register('salePrice', {
							pattern: {
								value: /^(\d*\.?\d+|\d+)$/, // Dozvoljava decimalni zapis broja ili undefined
								message: 'Must be a numeric value',
							},
							// validate: {
							// 	greaterThanZero: (value: any) => parseFloat(value) > 0 || 'Must be greater than zero',
							// },
						})}
					/>
				</div>
				<div className="flex flex-row items-center">
					<p></p>
				</div>
			</div>
			<div className="grid grid-cols-8 gap-2">
				<div className="flex flex-row items-center col-span-2">
					<p className="text-sm">Sale price dates</p>
				</div>
				<div className="col-span-5">
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<Controller
							name="saleFrom"
							control={control}
							defaultValue={null}
							rules={{ validate: validateDate }}
							render={({ field }) => (
								<DatePicker
									{...field}
									ref={datePickerRef}
									label="From date"
									minDate={dayjs(new Date())}
									onChange={(date) => {
										setValue('saleFrom', dayjs(date).toDate(), { shouldValidate: true });
										if (!!getValues('saleTo')) trigger('saleTo');
									}}
									onClose={() => handleModalClose()}
									slotProps={{
										textField: {
											helperText: errors.saleFrom?.message,
											error: !!errors.saleFrom,
										},
									}}
									sx={{
										width: '100%',
										maxWidth: '310px',
									}}
								/>
							)}
						/>
					</LocalizationProvider>
				</div>
				<div
					className="flex flex-row items-center cursor-pointer p-3"
					onClick={() => {
						setValue('saleFrom', null, { shouldValidate: true });
						setValue('saleTo', null, { shouldValidate: true });
					}}
				>
					<p className="text-sm">Cancel</p>
				</div>
			</div>
			<div className="grid grid-cols-8 gap-2">
				<div className="flex flex-row items-center col-span-2"></div>
				<div className="col-span-5">
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<Controller
							name="saleTo"
							control={control}
							defaultValue={null}
							rules={{ validate: validateSaleTo }}
							render={({ field }) => (
								<DatePicker
									{...field}
									label="To date"
									minDate={!!getValues('saleFrom') ? dayjs(getValues('saleFrom')) : dayjs(new Date())}
									onChange={(date) => {
										setValue('saleTo', dayjs(date).toDate(), { shouldValidate: true });
									}}
									onError={(error) => console.log('error ', error)}
									slotProps={{
										textField: {
											helperText: errors.saleTo?.message,
											error: !!errors.saleTo,
										},
									}}
									sx={{
										width: '100%',
										maxWidth: '310px',
									}}
								/>
							)}
						/>
					</LocalizationProvider>
				</div>
				<div className="flex flex-row items-center"></div>
			</div>
			<div className="w-full h-[1px] bg-black my-5"></div>
			<div className="grid grid-cols-8 gap-2">
				<div className="flex flex-row items-center col-span-2">
					<p className="text-sm">Weight (kg)</p>
				</div>
				<div className="col-span-5">
					<TextField
						type="text"
						className=" background-transparent "
						helperText={errors.weight?.message}
						error={!!errors.weight}
						fullWidth
						label="Weight"
						variant="filled"
						{...register('weight', {
							required: 'Field is required',
							pattern: {
								value: /^[+-]?\d+(\.\d+)?$/, // Dozvoljava decimalni zapis broja
								message: 'Must be a numeric value',
							},
							validate: {
								greaterThanZero: (value: any) => parseFloat(value) > 0 || 'Must be greater than zero',
							},
						})}
					/>
				</div>
				<div className="flex flex-row items-center">
					<p></p>
				</div>
			</div>
			<div className="grid grid-cols-8 gap-2">
				<div className="flex flex-row items-center col-span-2">
					<p className="text-sm">Dimensions (cm)</p>
				</div>
				<div className="col-span-5">
					<div className="grid grid-cols-3 gap-3">
						<div>
							<TextField
								type="text"
								className=" background-transparent "
								helperText={errors.height?.message}
								error={!!errors.height}
								fullWidth
								label="Height"
								variant="filled"
								{...register('height', {
									required: 'Field is required',
									pattern: {
										value: /^[+-]?\d+(\.\d+)?$/, // Dozvoljava decimalni zapis broja
										message: 'Must be a numeric value',
									},
									validate: {
										greaterThanZero: (value: any) => parseFloat(value) > 0 || 'Must be greater than zero',
									},
								})}
							/>
						</div>
						<div>
							<TextField
								type="text"
								className=" background-transparent "
								helperText={errors.length?.message}
								error={!!errors.length}
								fullWidth
								label="Length"
								variant="filled"
								{...register('length', {
									pattern: {
										value: /^(\d*\.?\d+|\d+)$/, // Dozvoljava decimalni zapis broja ili undefined
										message: 'Must be a numeric value',
									},
									// validate: {
									// 	greaterThanZero: (value: any) => parseFloat(value) > 0 || 'Must be greater than zero',
									// },
								})}
							/>
						</div>
						<div>
							<TextField
								type="text"
								className=" background-transparent "
								helperText={errors.width?.message}
								error={!!errors.width}
								fullWidth
								label="Width"
								variant="filled"
								{...register('width', {
									required: 'Field is required',
									pattern: {
										value: /^[+-]?\d+(\.\d+)?$/, // Dozvoljava decimalni zapis broja
										message: 'Must be a numeric value',
									},
									validate: {
										greaterThanZero: (value: any) => parseFloat(value) > 0 || 'Must be greater than zero',
									},
								})}
							/>
						</div>
					</div>
				</div>
				<div className="flex flex-row items-center">
					<p></p>
				</div>
			</div>
		</div>
	);
}

export default PriceForm;
