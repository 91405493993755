import React, { useState } from 'react';
import { Checkbox, FormControl, FormControlLabel, TextField } from '@mui/material';
import { Product } from './NewProduct';

function ManageStockForm({ commercialOption, setCommercialOption, register, errors, control, setValue }: { commercialOption: any; setCommercialOption: any; register: any; errors: any; control: any; setValue: any }) {
	return (
		<div className="flex  w-full flex-col  border border-mediumGrey rounded-md py-[20px] px-3 gap-5">
			<div className="grid grid-cols-8 gap-2">
				<div className="flex flex-row items-center col-span-2">
					<p className="text-sm">Manage stock?</p>
				</div>
				<div className="col-span-5">
					<FormControl>
						<FormControlLabel control={<Checkbox checked={commercialOption.manageStock} onChange={(e: any) => setCommercialOption({ ...commercialOption, manageStock: e.target.checked })} />} label="" />
					</FormControl>
				</div>
				<div className="flex flex-row items-center">
					<p></p>
				</div>
			</div>
			{/* <div className="grid grid-cols-8 gap-2">
				<div className="flex flex-row items-center col-span-2">
					<p className="text-sm">In stock</p>
				</div>
				<div className="col-span-5">
					<FormControl sx={{ width: '100%' }}>
						<FormControlLabel control={<Checkbox checked={commercialOption.inStock} onChange={(e: any) => setCommercialOption({ ...commercialOption, inStock: e.target.checked })} />} label="" />
					</FormControl>
				</div>
				<div className="flex flex-row items-center">
					<p></p>
				</div>
			</div> */}
			<div className="w-full h-[1px] bg-black my-5"></div>
			<div className="grid grid-cols-8 gap-2">
				<div className="flex flex-row items-center col-span-2">
					<p className="text-sm">Sold individually</p>
				</div>
				<div className="col-span-5">
					<FormControl>
						<FormControlLabel control={<Checkbox checked={commercialOption.soldIndividually} onChange={(e: any) => setCommercialOption({ ...commercialOption, soldIndividually: e.target.checked })} />} label="Limit purchases to 1 item per order" />
					</FormControl>
				</div>
				<div className="flex flex-row items-center">
					<p></p>
				</div>
			</div>
			<div className="grid grid-cols-8 gap-2">
				<div className="flex flex-row items-center col-span-2">
					<p className="text-sm">Quantity</p>
				</div>
				<div className="col-span-5">
					<TextField
						type="text"
						className=" background-transparent "
						helperText={errors.quantity?.message}
						error={!!errors.quantity}
						fullWidth
						label="Quantity"
						variant="filled"
						{...register('quantity', {
							required: 'Field is required',
							pattern: {
								value: /^[+-]?\d+(\.\d+)?$/, // Dozvoljava decimalni zapis broja
								message: 'Must be a numeric value',
							},
							validate: {
								notNegativeNumber: (value: any) => parseFloat(value) >= 0 || 'Must be equal to or greater than zero',
							},
						})}
					/>
				</div>
				<div className="flex flex-row items-center">
					<p></p>
				</div>
			</div>
			<div className="grid grid-cols-8 gap-2">
				<div className="flex flex-row items-center col-span-2">
					<p className="text-sm">Critical quantity</p>
				</div>
				<div className="col-span-5">
					<TextField
						type="text"
						className=" background-transparent "
						helperText={errors.criticalQuantity?.message}
						error={!!errors.criticalQuantity}
						fullWidth
						label="Critical quantity"
						variant="filled"
						{...register('criticalQuantity', {
							required: 'Field is required',
							pattern: {
								value: /^[+-]?\d+(\.\d+)?$/, // Dozvoljava decimalni zapis broja
								message: 'Must be a numeric value',
							},
							validate: {
								notNegativeNumber: (value: any) => parseFloat(value) >= 0 || 'Must be equal to or greater than zero',
							},
						})}
					/>
				</div>
				<div className="flex flex-row items-center">
					<p></p>
				</div>
			</div>
		</div>
	);
}

export default ManageStockForm;
