import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { FaTimes } from 'react-icons/fa';
import { Button, TextField } from '@mui/material';
import { getImage, uploadFile } from '../../../server/server';
import AddProductsImageModal from './AddProductsImageModal';
import AddFileFromLibraryModal from '../../media/AddFileFromLibraryModal';
import Loading from '../../../common/Loading';

function SingleSpecificationModal({ modalIsOpen, closeModal, specification, save }: { modalIsOpen: boolean; closeModal: () => void; specification: any; save: (specification: any) => void }) {
	const [text, setText] = useState('');
	const [image, setImage] = useState('');
	const [addImageModalOpen, setAddImageModalOpen] = useState(false);
	const [libraryModalOpen, setLibraryModalOpen] = useState(false);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (modalIsOpen) {
			setText(specification?.text);
			setImage(specification?.image ?? '');
		} else {
			setText('');
			setImage('');
		}
	}, [modalIsOpen]);

	const reset = () => {};

	const saveSpecification = () => {
		save({ ...specification, text: text, image: image });
	};

	const closeAddImageModal = () => {
		setAddImageModalOpen(false);
	};

	const closeLibraryModal = () => {
		setLibraryModalOpen(false);
	};

	const uploadImage = async (file: any) => {
		if (!!file) {
			setLoading(true);
			const resp = await uploadFile({ file: file, selectable: 'true' });
			if (resp.status == 201) {
				const uploadedImage = resp.data;
				setImage(uploadedImage.id);
				setLoading(false);
				closeAddImageModal();
			} else {
			}
		}
	};

	const handleSelectedFile = (id: string) => {
		setImage(id);
		closeLibraryModal();
	};
	return (
		<ReactModal
			ariaHideApp={false}
			//closeTimeoutMS={600}
			isOpen={modalIsOpen}
			onAfterOpen={() => {}}
			onAfterClose={() => reset()}
			onRequestClose={closeModal}
			className="no-scroll"
			style={{
				overlay: {
					zIndex: 5,
					backdropFilter: 'brightness(30%)',
				},
				content: {
					padding: 0,
					opacity: 1,
					width: 600,
					margin: 'auto',
					backgroundColor: 'transparent',
					height: '100%',
					maxHeight: '100%',
					border: 'none',
					boxShadow: 'none',
					overflow: 'auto',
					paddingTop: 15,
					paddingBottom: 15,
				},
			}}
		>
			<div className="   max-h-[600px] w-full  ">
				<div className=" flex flex-1 flex-col gap-5 rounded-[10px] bg-white  px-[40px] py-[30px] ">
					<div className={` border border-[#aeaeae] min-h-[100px] flex items-center justify-center`}>
						{!!image && <img className="w-full object-contain object-top" src={getImage(image)} alt="" height={'100%'} />}
						{!image && (
							<div className="flex flex-row gap-[10px]">
								{!loading && (
									<>
										<Button variant="contained" style={{ backgroundColor: '#2ec4b6', color: 'white' }} onClick={() => setAddImageModalOpen(true)}>
											Upload image
										</Button>
										<Button variant="contained" style={{ backgroundColor: '#2ec4b6', color: 'white' }} onClick={() => setLibraryModalOpen(true)}>
											Chose from library
										</Button>
									</>
								)}
								{loading && <Loading height={20} />}
							</div>
						)}
					</div>
					<TextField label="Text" value={text} onChange={(e) => setText(e.target.value)} />
					<div className="flex w-full flex-row justify-end gap-8 mt-10  ">
						<Button variant="contained" onClick={() => closeModal()} style={{ backgroundColor: '#2ec4b6', color: 'white' }}>
							Close
						</Button>
						<Button variant="contained" onClick={saveSpecification} style={{ backgroundColor: '#2ec4b6', color: 'white' }}>
							Save
						</Button>
					</div>
				</div>
			</div>
			<AddProductsImageModal modalIsOpen={addImageModalOpen} closeModal={closeAddImageModal} setSelectedFileToList={uploadImage} loading={loading} />
			<AddFileFromLibraryModal modalIsOpen={libraryModalOpen} closeModal={closeLibraryModal} returnSelectedFile={handleSelectedFile} />
		</ReactModal>
	);
}

export default SingleSpecificationModal;
