export function hexToHSLA(H: string): { h: number; s: number; l: number; a: number } {
	let r = 0,
		g = 0,
		b = 0,
		a = 1;
	if (H.length == 5) {
		r = parseInt(H[1] + H[1], 16);
		g = parseInt(H[2] + H[2], 16);
		b = parseInt(H[3] + H[3], 16);
		a = parseInt(H[4] + H[4], 16);
	} else if (H.length == 9) {
		r = parseInt(H[1] + H[2], 16);
		g = parseInt(H[3] + H[4], 16);
		b = parseInt(H[5] + H[6], 16);
		a = parseInt(H[7] + H[8], 16);
	}
	r /= 255;
	g /= 255;
	b /= 255;
	a /= 255;
	let max = Math.max(r, g, b),
		min = Math.min(r, g, b);
	let h = 0,
		s: number,
		l = (max + min) / 2;

	if (max == min) {
		h = s = 0;
	} else {
		let d = max - min;
		s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
		switch (max) {
			case r:
				h = (g - b) / d + (g < b ? 6 : 0);
				break;
			case g:
				h = (b - r) / d + 2;
				break;
			case b:
				h = (r - g) / d + 4;
				break;
		}
		h /= 6;
	}
	return { h: h * 360, s: s * 100, l: l * 100, a: a };
}
